
<template>
  <div class="container my-3 p-3 bg-light rounded box-shadow">
    <form @submit.prevent="validateAndSubmit" id="formUser" >
      <div class="form-row">
        <div class="form-group col-md-9">
          <label for="name">Nome</label>
          <input type="text" class="form-control" id="name" placeholder="Nome" v-model="modality.name" autofocus="autofocus" required="required">
        </div>
      </div>

      <div class="form-group">
        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="inative">
          <label class="form-check-label" for="inative">
            Inativo
          </label>
        </div>
      </div>
      <div style="float: right;">
        <button type="button" class="btn btn-primary" v-on:click="validateAndSubmit">Salvar</button>
        <router-link class="btn btn-default" style="margin-left:10px;" to="/modalities">Fechar</router-link>
      </div>
    </form>
  </div>
</template>

<script>
import ApiAxios from '../../services/api-axios';
import { sAlert } from '../utils/jalerts';

export default {
  props: {
    save: Function,
  },
  data() {
    return {
      modality: {},
      validationErrors: [],
    };
  },
  mounted() {
    this.fetchGetModality();
  },
  methods: {
    async fetchGetModality() {
      if(!this.$route.params.id) return;
      this.$root.loadingVisible = true;
      const res = await ApiAxios.get(`modalities/${this.$route.params.id}`);
      this.modality = res.data.modality;
      this.$root.loadingVisible = false;
    },
    validateAndSubmit() {
      this.validationErrors = [];
      if (!this.validate()) {
        return sAlert({
          type: 'warning',
          text: 'Ops! ' + this.validationErrors,
        });
      }
      
      this.save( this.modality );
    },
    validate() {
      const errors = [];
      if (!this.modality.name) errors.push("Nome inválido");

      if (errors) this.validationErrors = errors;
      else this.validationErrors = [];

      return !this.validationErrors.length;
    },
  },
}
</script>
